import iconAltMedicine from "../../assets/images/icons/iconAltMedicine.svg";
import iconDental from "../../assets/images/icons/iconDental.svg";
import iconDogCat from "../../assets/images/icons/iconDogCat.svg"; 
import iconEmergency from "../../assets/images/icons/iconEmergency.svg";
import iconEOL from "../../assets/images/icons/iconEndOfLife.svg";
import iconMicrochip from "../../assets/images/icons/iconMicrochip.svg";
import iconPharmacy from "../../assets/images/icons/iconPharmacy.svg";
import iconPreventative from "../../assets/images/icons/iconVaccination.svg"; 
import iconSpayNeuter from "../../assets/images/icons/iconSpayNeuter.svg"; 
import iconSurgery from "../../assets/images/icons/iconSurgery.svg";
import iconWellness from "../../assets/images/icons/iconWellness.svg";
import iconXray from "../../assets/images/icons/iconXray2.svg"; 



const equine = {
    sideText: "Animal Medical Center of Emmett makes caring for horses easy. We offer convenient scheduling, and we strive to make our equine veterinary services as simple and affordable as possible. To schedule an appointment, please contact us.",
    textList: true,
    hrVisible: true,
    data: [
        {
            img: iconWellness,
            altText: "...",
            title: "Exams",
            text: [
                {data: "Wellness"},
                {data: "Lameness"},
                {data: "Reproduction"},
                {data: "Sick Patient"}
            ],
            link: "/"
        },
        {
            img: iconXray,
            altText: "...",
            title: "Diagnostics",
            text: [
                {data: "Digital Radiograph"},
                {data: "Ultrasound"},
                {data: "Gastroscopy / Endoscopy"},
                {data: "In-house Laboratory"},
                {data: "Reference Laboratory"}
            ],
            link: "/"
        },
        {
            img: iconPreventative,
            altText: "...",
            title: "Preventatives",
            text: [
                {data: "Vaccinations"},
                {data: "Parasite Control"}
            ],
            link: "/"
        },
        {
            img: iconMicrochip,
            altText: "...",
            title: "Microchipping",
            text: "",
            link: "/"
        },
        {
            img: iconDental,
            altText: "...",
            title: "Dental Care",
            text: "",
            link: "/"
        },
        {
            img: iconSurgery,
            altText: "...",
            title: "Minor Surgeries",
            text: "",
            link: "/"
        },
        {
            img: iconSurgery,
            altText: "...",
            title: "Obstetrics Services",
            text: "",
            link: "/"
        },
        {
            img: iconEOL,
            altText: "...",
            title: "End of Life Services",
            text: "",
            link: "/"
        },
        {
            img: iconEmergency,
            altText: "...",
            title: "Emergency Services",
            text: "",
            link: "/"
        },
        {
            img: iconAltMedicine,
            altText: "...",
            title: "Specialty Treatments",
            text: [
                {data: "Chiropractic"},
                {data: "Laser Therapy"},
                {data: "Joint Injections"}
            ],
            link: "/"
        }
    ]
};

const ruminant = {
    sideText: "Animal Medical Center of Emmett does farm calls in the afternoon throughout the week, and we strive to make our services as convenient and affordable as possible. If you need a compassionate large animal veterinarian, please contact our office today.",
    textList: true,
    hrVisible: true,
    data: [
        {
            img: iconWellness,
            altText: "...",
            title: "Herd Health",
            text: "",
            link: "/"
        },
        {
            img: iconWellness,
            altText: "...",
            title: "Regulatory Services",
            text: "",
            link: "/"
        },
        {
            img: iconPreventative,
            altText: "...",
            title: "Preventatives",
            text: [
                {data: "Vaccinations"},
                {data: "Parasite Control"}
            ],
            link: "/"
        },
        {
            img: iconPreventative,
            altText: "...",
            title: "Obstetrics Services",
            text: "",
            link: "/"
        },
        {
            img: iconPreventative,
            altText: "...",
            title: "Breeding Soundness",
            text: "",
            link: "/"
        },
        {
            img: iconXray,
            altText: "...",
            title: "Diagnostics",
            text: [
                {data: "Digital Radiograph"},
                {data: "Ultrasound"},
                {data: "Gastroscopy / Endoscopy"},
                {data: "In-house Laboratory"},
                {data: "Reference Laboratory"}
            ],
            link: "/"
        },
        {
            img: iconSurgery,
            altText: "...",
            title: "Surgery",
            text: "",
            link: "/"
        },
        {
            img: iconEmergency,
            altText: "...",
            title: "Emergency Services",
            text: "",
            link: "/"
        }
    ]
};

const smallAnimal = {
    sideText: "Experience top-tier veterinary care delivered with utmost compassion for your beloved furry family members at every stage of their lives.",
    textList: true,
    hrVisible: true,
    data: [
        {
            img: iconWellness,
            altText: "...",
            title: "Wellness Exam & Sick Pet Exam",
            text: "",
            link: "/"
        },
        {
            img: iconPreventative,
            altText: "...",
            title: "Preventatives",
            text: [
                {data: "Vaccinations"},
                {data: "Parasite Control"}
            ],
            link: "/"
        },
        {
            img: iconMicrochip,
            altText: "...",
            title: "Microchipping",
            text: "",
            link: "/"
        },
        {
            img: iconDental,
            altText: "...",
            title: "Dental Care",
            text: "",
            link: "/"
        },
        {
            img: iconSurgery,
            altText: "...",
            title: "Surgery",
            text: [
                {data: "Soft tissue"},
                {data: "Orthopedic"}
            ],
            link: "/"
        },
        {
            img: iconXray,
            altText: "...",
            title: "Diagnostics",
            text: [
                {data: "Digital Radiograph"},
                {data: "Ultrasound"},
                {data: "In-house Laboratory"},
                {data: "Reference Laboratory"}
            ],
            link: "/"
        },
        {
            img: iconPharmacy,
            altText: "...",
            title: "In-house Pharmacy",
            text: "",
            link: "/"
        },
        {
            img: iconAltMedicine,
            altText: "...",
            title: "Specialty Treatments",
            text: [
                {data: "Chiropractic"},
                {data: "Laser Therapy"},
                {data: "Stemcell"},
                {data: "PRP"}
            ],
            link: "/"
        },
        {
            img: iconEOL,
            altText: "...",
            title: "End of Life Services",
            text: "",
            link: "/"
        },
        {
            img: iconEmergency,
            altText: "...",
            title: "Emergency Services",
            text: "",
            link: "/"
        }
    ]
};

const puppyPackage = {
    sideText: "We’ve bundled all the essential services to meet the unique needs of your furry friend during this crucial stage of their development.",
    textList: true,
    hrVisible: true,
    data: [
        {
            img: iconPreventative,
            altText: "...",
            title: "Puppy Vaccination Series",
            // text: [
            //     {data: "3 Rounds of Distemper Parvo"},
            //     {data: "1 Year Rabies"},
            //     {data: "Year Bordetella"}
            // ],
            link: "/"
        },
        {
            img: iconWellness,
            altText: "...",
            title: "Puppy Wellness Exam",
            text: "",
            link: "/"
        },
        {
            img: iconSpayNeuter,
            altText: "...",
            title: "10% OFF Spay/Neuter",
            text: "",
            link: "/"
        },
        
        {
            img: iconDogCat,
            altText: "...",
            title: "Deworming",
            text: "",
            link: "/"
        }
    ]
};

const kittenPackage = {
    sideText: "Our specially designed bundle of services is crafted to cater to the unique needs of your adorable furball during this important phase of their growth.",
    textList: true,
    hrVisible: true,
    data: [
        {
            img: iconPreventative,
            altText: "...",
            title: "Kitten Vaccination Series",
            // text: [
            //     {data: "3 Rounds of FVRCP"},
            //     {data: "2 Rounds FeLV"},
            //     {data: "1 Year Rabies"}
            // ],
            link: "/"
        },
        {
            img: iconWellness,
            altText: "...",
            title: "Kitten Wellness Exam",
            text: "",
            link: "/"
        },
        {
            img: iconSpayNeuter,
            altText: "...",
            title: "10% OFF Spay/Neuter",
            text: "",
            link: "/"
        },
        
        {
            img: iconDogCat,
            altText: "...",
            title: "Deworming",
            text: "",
            link: "/"
        }
    ]
};

const serviceCardsData = {
    equine,
    ruminant,
    smallAnimal,
    puppyPackage,
    kittenPackage
};

export default serviceCardsData;
import React from "react";
import { ToastContainer, Toast } from "react-bootstrap";

import "./scss/snackbar_toast.scss";

const SnackbarToast = (props) => {
    const { toast, message, onClose } = props;

    return (
        <ToastContainer containerPosition="fixed" position="middle-center">
            <Toast 
                show={true} 
                onClose={onClose} 
                animation={true} 
                className={toast.theme} 
                delay={4500} 
                autohide
            >
                <Toast.Header>
                    <strong className="me-auto">{process.env.REACT_APP_DISPLAY_NAME_SHORT}</strong>
                </Toast.Header>
                <Toast.Body><i className={toast.icon} />{message}</Toast.Body>
            </Toast>
        </ToastContainer>
    );
};

export default SnackbarToast;
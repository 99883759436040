const scratchpayData = {
    data: [
        {
            question: "What is Scratchpay",
            answer: "Scratchpay is a payment plan for veterinary costs with fixed, monthly payments. It is not a credit card or a line of credit, so it doesn’t rely on your credit score or affect your credit score, and more pet parents get approved.",
            sortOrder: 0
        },
        {
            question: "How do I apply for Scratchpay",
            answer: <>
                <p>Apply at Scratchpay.com from any internet-connected device and receive a plan decision instantly.  Applications are risk-free, and finding a payment plan will never impact your credit score.</p>
                <p>Applicants must be U.S. or Canadian Residents that are 18 years of age or older. Children under the age of 18 are not eligible to participate in the offerings on this website.</p>
            </>,
            sortOrder: 0
        },
        {
            question: "Is Scratchpay accepted by all veterinary clinics?",
            answer: "No. Scratchpay is exclusively accepted at partner clinics that have registered to provide their clients these services, such as AMC.",
            sortOrder: 0
        },
        {
            question: "What kind of medical bills can I use Scratchpay to pay?",
            answer: <>
                <p>As long as your veterinarian is a Scratchpay partner like AMC, you can apply to use Scratchpay to create payment plans for as may expenses as you need.</p>
                <p>Applying will not affect your credit score and you will be informed about what plans are available for your pet's care within seconds of applying.</p>
            </>,
            sortOrder: 0
        },
        {
            question: "How do I get the Scratchpay App?",
            answer: <><p>The app is simple and free to download! </p>
                <p>Visit the Apple App Store or the Google Play Store to download the Scratch Plans app.</p>
                <p>With our app, you can easily make payments, get 1-click access to autopay, keep track of all loan statements, update account information, reapply for new plans directly in-app, and much more!</p></>,
            sortOrder: 0
        }
        
    ]
};

const faqData = {
    scratchpayData
};

export default faqData;
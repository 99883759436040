import React from "react";
import { Container, Col, Row, Breadcrumb } from "react-bootstrap";

import "./scss/every_hero.scss";

/**
 * EveryHero is a responsive custom hero image implementation.
 */
const EveryHero = (props) => {
    let { image, title, subtitle, text, bgColor="", mobileAlign="", breadcrumb } = props;

    return (
        <Container fluid className={`mt-1 every-hero-color-block ${bgColor}`}>
            <Container className="every-hero-container max-width-xl">
                <Container>
                    <Row >
                        <Col sm={12} lg={6}>
                            <div className={`every-hero-image-container rounded ${mobileAlign}`} style={{backgroundImage: `url(${image})`}}/>
                        </Col>
                        {breadcrumb && 
                        <Breadcrumb className="breadcrumbs-services-sm">
                            <Breadcrumb.Item href={breadcrumb.path}>{breadcrumb.previous}</Breadcrumb.Item>
                            <Breadcrumb.Item active>{breadcrumb.active}</Breadcrumb.Item>
                        </Breadcrumb>}
                        <Col sm={12} lg={6} className="mt-4">
                            <h1>{title}</h1>
                            <h5>{subtitle}</h5>
                            <p>{text}</p>
                        </Col>
                    </Row>
                </Container>         
            </Container>
        </Container>
    );
};

export default EveryHero;
import React from "react";

import { Breadcrumb, Card, Col, Container, Row, Accordion } from "react-bootstrap";
import serviceCardsData from "../../util/staticData/serviceCards.data";
import formatTitle from "../../util/formatTitle";
import "../../assets/css/_services.scss";


const ServicesDetailAlt = (props) => {
    let serviceCardData = serviceCardsData[props.data];

    return (
        <Container>
            <Row className="breadcrumbs-services-lg"> 
                <Breadcrumb>
                    <Breadcrumb.Item href="/services">Services</Breadcrumb.Item>
                    <Breadcrumb.Item active>{props.title}</Breadcrumb.Item>
                </Breadcrumb>
            </Row>
            <Row>
                <Col sm={12} lg={4} className="my-2">
                    {serviceCardData.sideText}
                </Col>
                <Col sm={12} lg={8} className="my-2">
                    <Row>
                        {serviceCardData.data.map((card, index) => {
                            return (
                                <Col
                                    sm={6}
                                    md={4}
                                    className="mb-2 services-card-column"
                                >
                                    <Accordion className="services-accordion">
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header className={card.text ? "services-accordion-header" : "services-accordion-header no-arrow"}>
                                                <Card className="services-card">
                                                    <Card.Img src={card.img} style={{height: "5rem", width: "5rem", marginTop: ".5rem"}} />
                                                    <Card.Body className="p-2 d-flex align-items-center services-card-body-alt">
                                                        <h5>{formatTitle(card.title, ";", 2)}</h5>
                                                    </Card.Body>
                                                </Card>
                                            </Accordion.Header>
                                            {card.text && <Accordion.Body>
                                                {typeof card.text === "object" ? (
                                                    <ul >
                                                        {card.text.map((item, index) => {
                                                            return (
                                                                <li key={index}>{item.data}</li>
                                                            );
                                                                
                                                        }
                                                        )}
                                                    </ul>
                                                ) : <p>{card.text}</p> }
                                            </Accordion.Body> }
                                        </Accordion.Item>
                                    </Accordion> 
                                </Col>
                            );
                        })}
                    </Row>
                </Col>
            </Row>

        </Container>
    );
};

export default ServicesDetailAlt;
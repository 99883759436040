import React from "react";
import { Container } from "react-bootstrap";

import EveryHero from "../global/every_hero";
import PageCard from "../global/page_card";

import imgServicesHero from "../../assets/images/imageHeroEquipment.jpeg";

import "../../assets/css/_breadcrumbs.scss";

const Services = () => {

    return (
        <>
            <EveryHero 
                image={imgServicesHero} 
                title="Services" 
                text={<>As a mixed-animal practice, {process.env.REACT_APP_DISPLAY_NAME} offers a wide range of services for dogs and cats as well as equine/large animal services. Our animal hospital focuses on wellness services for pets and farm animals, and our veterinarians treat all animals with care and compassion. Our services include:</>}
                bgColor="primary"
            />
            <Container className="max-width-xl">
                <PageCard data="services"/>
            </Container>
        </>
    );
};

export default Services;
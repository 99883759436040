import React from "react";
import { Button, Container, Col, Row } from "react-bootstrap";

//import imageMainHero from "../../assets/images/collage.png";
import imageMainHero from "../../assets/images/amc-home-hero.webp";

import "./scss/main_hero.scss";

const MainHero = () => {
    return (
        <Container className="main-hero-container">
            <Row>
                <Col xs="12" md="6">
                    <h1>Providing High Quality Veterinary Services for Both Large and Small Animals</h1>
                    <Button href="/request-appointment">Book An Appointment</Button>
                </Col>
                <Col xs="12" md="6">
                    <img src={imageMainHero} alt="" />
                </Col>
            </Row>
        </Container>
    );
};

export default MainHero;
import React from "react";
import { Container } from "react-bootstrap";

import EveryHero from "../global/every_hero";
import ServicesDetailAlt from "./services_detail_alt";

import imgPuppyHero from "../../assets/images/imageHero05.webp";

const PuppyPackage = () => {


    return (
        <>
            <EveryHero image={imgPuppyHero} 
                breadcrumb={{active: "Puppy Package", previous: "Services", path: "/services"}}
                bgColor="primary"
                title="Puppy Package" 
                text={<>Give your new puppy the best start in life with our comprehensive puppy package.</>}
            />
            <Container className="max-width-xl">
                <ServicesDetailAlt data="puppyPackage" title="Puppy Package"/>
            </Container>
        </>
    );
};

export default PuppyPackage;
import imgHero04 from "../../assets/images/imageHero04.jpeg";
import imgHero05 from "../../assets/images/imageHero05.webp";
import imgSheep from "../../assets/images/sheep.jpg";
import imgExterior from "../../assets/images/amc-exterior.webp";
import imgDogCat from "../../assets/images/dog_cat.jpg";
import imgHorseColt from "../../assets/images/horse_colt.jpg";
import imgGroupPhoto from "../../assets/images/saved_from_old_site/slideAbout.webp";
import imgPuppiesLab from "../../assets/images/puppies_lab.jpeg";
import imgKitten from "../../assets/images/kitten.webp";
import logoAAHA from "../../assets/images/logoAAHA.webp";

const homepage = {
    imgLocation: "alternate",
    hrVisible: true,
    data: [
        {
            img: imgExterior,
            altText: "office exterior",
            title: "Welcome",
            text: "Welcome to Animal Medical Center of Emmett. Conveniently located just northwest of Boise in Emmett, we have been providing the Gem County, Idaho, area with high-quality veterinary services for several decades. We are a mixed-animal practice, and we treat dogs, cats, horses, cattle, and farm animals. We offer a complete range of services for animals large and small, and we strive to keep our prices as affordable as possible."
        },
        {
            img: imgGroupPhoto,
            altText: "staff photo",
            title: "Caring Doctors and Staff",
            text: "Since assuming leadership in 2015, Dr. Dan Dombroski and his dedicated team have been delivering exceptional and compassionate veterinary care. Deeply committed to the wellbeing of pets and farm animals in Gem County, Animal Medical Center of Emmett offers superior yet affordable services. When you trust us with your animals, you know they'll be treated like our own ... just like they are part of our family!",
            link: "/about-us"
        },
        {
            img: imgPuppiesLab,
            altText: "animals",
            title: "Schedule an Appointment",
            text: "Conveniently located in Emmett, Idaho, Animal Medical Center of Emmett provides compassionate, high-quality veterinary services for animals throughout Gem County. We are a mixed-animal facility, and we primarily treat dogs, cats, horses, cattle, and other farm animals.",
            link: "/request-appointment",
            buttonText: "Book An Appointment"
        },
        {
            img: logoAAHA,
            altText: "AAHA Accredited",
            title: "We're AAHA Accredited!",
            text: "At Animal Medical Center, we understand you want the best care available for your furry family members. That is why our hospital has implemented the 900+ standards required to become an AAHA certified hospital.  This is a designation that only the top 15% of veterinary hospitals will achieve, so you can feel confident that you have selected a hospital committed to and certified in veterinary excellence."
        }
    ]
};

const forms = {
    imgLocation: "top",
    hrVisible: true,
    data: [
        {
            img: imgHero04,
            altText: "...",
            title: "New Patient Form",
            buttonText: "New Patient Form",
            text: "Tell us about you and your pet.",
            link: "/new-patient"
        },
        {
            img: imgHero05,
            altText: "...",
            title: "Request Appointment",
            buttonText: "Request Appointment",
            text: "Request an appointment.",
            link: "/request-appointment"
        }
    ]
};

const services = {
    imgLocation: "top",
    hrVisible: true,
    data: [
        {
            img: imgDogCat,
            altText: "...",
            title: "Small Animal",
            buttonText: "Learn More",
            text: "From wellness exams to specialty services, we'll take excellent care of your furry friends.",
            link: "/services/small-animal"
        },
        {
            img: imgHorseColt,
            altText: "...",
            title: "Equine",
            buttonText: "Learn More",
            text: "We fully grasp the bond between you and your horse and are dedicated to providing the care you expect.",
            link: "/services/equine"
        },
        {
            img: imgSheep,
            altText: "...",
            title: "Ruminant",
            buttonText: "Learn More",
            text: "We understand the unique needs of large animals, and are equipped to provide high-quality services.",
            link: "/services/ruminant"
        },
        {
            img: imgHero05,
            altText: "...",
            title: "Puppy Package",
            buttonText: "Learn More",
            text: "Give your new puppy the best start in life with our comprehensive puppy package.",
            link: "/services/puppy-package"
        },
        {
            img: imgKitten,
            altText: "...",
            title: "Kitten Package",
            buttonText: "Learn More",
            text: "Set your new kitten up for a lifetime of purrs and playfulness with our comprehensive kitten package.",
            link: "/services/kitten-package"
        }
    ]
};

const pageCardsData = {
    homepage,
    forms,
    services
};

export default pageCardsData;
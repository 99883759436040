import React from "react";
import { Container } from "react-bootstrap";

import EveryHero from "../global/every_hero";
import ServicesDetailAlt from "./services_detail_alt";

import imgKittenHero from "../../assets/images/kitten.webp";

const KittenPackage = () => {


    return (
        <>
            <EveryHero image={imgKittenHero} 
                breadcrumb={{active: "Kitten Package", previous: "Services", path: "/services"}}
                bgColor="primary"
                title="Kitten Package" 
                text={<>Set your new kitten up for a lifetime of purrs and playfulness with our comprehensive kitten package.</>}
            />
            <Container className="max-width-xl">
                <ServicesDetailAlt data="kittenPackage" title="Kitten Package"/>
            </Container>
        </>
    );
};

export default KittenPackage;
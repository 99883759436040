import React, { useRef, useState } from "react";

import { Col, Container, Image, Modal, Row } from "react-bootstrap";
import teamBioData from "../../util/staticData/teamBio.data";
import staffCategoryData from "../../util/staticData/staffCategory.data";
import formatTitle from "../../util/formatTitle";
import formatDisplayName from "../../util/formatDisplayName";

import "./scss/every_circle.scss";

const EveryCircle = (props) => {
    let pageCardData = teamBioData[props.data];

    const [modalShow, setModalShow] = useState(false);
    const modalName = useRef(null);
    const modalTitle = useRef(null);
    const modalText = useRef(null);
    const modalImage = useRef(null);

    const displayModal = (bio) => {
        modalName.current = formatDisplayName(bio);
        modalTitle.current = formatTitle(bio.title);
        modalText.current = bio.text;
        modalImage.current = bio.image;
        setModalShow(true);
    };

    const staffList = (category_id) => {

        const filteredList = pageCardData.data.filter(staff => staff.staff_category_id === category_id);
        const sortedFiltered = filteredList.sort((a,b)=> (a.last_name - b.last_name || a.sortOrderOverride > b.sortOrderOverride ? 1 : -1 || a.title.localeCompare(b.title)));

        const staffList = 
                <Container>
                    <Row>
                        {sortedFiltered
                            .map((bio, index) =>  {
                                return (
                                    <Col
                                        className="mb-4"
                                        md={6}
                                        lg={3}
                                        key={index}>
                                        <div className="every-circle-link" href="#" onClick={() => displayModal(bio)}>
                                            <div className="every-circle-img-container">
                                                <div className="every-circle-img" style={{ backgroundImage: `url(${bio.image})` }}></div>
                                            </div>
                                            <div className="p-1 text-center">
                                                <h6>{formatDisplayName(bio)}</h6>
                                                <div>{formatTitle(bio.title, "/", 0)}</div>
                                            </div>
                                        </div>
                                    </Col>

                                );
                            })}
                    </Row>
                    <Modal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton className="pb-0" style={{ border: "0" }}></Modal.Header>
                        <Modal.Body>
                            <Container>
                                <Row>
                                    <Col sm={12} lg={4} className="mb-3">
                                        <Image src={modalImage.current} rounded style={{ width: "100%" }} />
                                    </Col>
                                    <Col>
                                        <h4>{modalName.current}</h4>
                                        <h6>{modalTitle.current}</h6>
                                        <div className="every-circle-modal-title-separator">&nbsp;</div>
                                        <p>{modalText.current}</p>
                                    </Col>
                                </Row>
                            </Container>
                        </Modal.Body>
                    </Modal>
                </Container>;
        return staffList;
    };

    return (
        <Container className="max-width-xl">
            {staffCategoryData.data
                .sort((a, b) => a.sequence - b.sequence)
                .map((staffCategoryData) => {
                    return (
                        <div key={staffCategoryData.id} className="my-5">
                            {/* <h1 className="text-center mb-4">{staffCategoryData.category_name}</h1> */}
                            {staffList(staffCategoryData.id)}
                        </div>
                    );
                })}
        </Container>
    );
};

export default EveryCircle;
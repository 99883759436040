import React from "react";
import { Container } from "react-bootstrap";

import EveryHero from "../global/every_hero";
import ServicesDetailAlt from "./services_detail_alt";

import imgServicesHero from "../../assets/images/sheep.jpg";

const Ruminant = () => {

    return (
        <>
            <EveryHero image={imgServicesHero} 
                breadcrumb={{active: "Ruminant", previous: "Services", path: "/services"}}
                bgColor="primary"
                title="Ruminant" 
                text={<>Caring for large animals, including farm animals, is a specialized service that many veterinarians do not offer. These animals often must be seen in their homes rather than in a vet’s office, and they have unique needs that make caring for them a challenge. At {process.env.REACT_APP_DISPLAY_NAME}, we are a mixed-animal practice treating everything from dogs and cats to large animals, including farm animals.</>}
            />
            <Container className="max-width-xl">
                <ServicesDetailAlt data="ruminant" title="Ruminant"/>
            </Container>
        </>
    );
};

export default Ruminant;
// Main page loader
import React from "react";
import { Container } from "react-bootstrap";

import MainHero from "./main_hero";
import MainStakes from "./main_stakes";
import PageCard from "../global/page_card";

const Main = (props) => {
    //let { showSnack } = props;
    return (
        <>
            <Container className="max-width-xl">
                <MainHero />
            </Container>
            <MainStakes />
            <Container className="mt-2 max-width-xl">
                <PageCard data="homepage"/>
            </Container>
        </>
    );
};

export default Main;
import React from "react";

import { Button, Card, Col, Container, Row } from "react-bootstrap";
import pageCardsData from "../../util/staticData/pageCards.data";

import "./scss/page_card.scss";

const PageCard = (props) => {
    let pageCardData = pageCardsData[props.data];

    const getRowPosition = () => {
        if (pageCardData.imgLocation === "top" && Object.keys(pageCardData.data).length < 3) {
            return "justify-content-md-center";
        }
        return "";
    };

    const getFlexDirection = (location, index) => {
        switch (location) {
            case "alternate":
                if (index % 2 !== 0) {
                    return "img-right flex-row-reverse";
                }
                return "img-left";
            case "right":
                return "img-right flex-row-reverse";
            case "left":
                return "img-left";
            default: 
                return `img-${location}`;
        }
    };
    return (
        <Container>
            <Row className={getRowPosition()}>
                {pageCardData.data.map((card, index) => {
                    return (
                        <Col 
                            className="mb-4"
                            md={pageCardData.imgLocation === "top" ? 6 : 12} 
                            lg={pageCardData.imgLocation === "top" ? 4 : 12} 
                            key={index}>
                            <Card>
                                <Row className={getFlexDirection(pageCardData.imgLocation, index)}>
                                    <Col sm={12} md={pageCardData.imgLocation === "top" ? 12 : 6} >
                                        <Card.Img src={card.img} alt={card.altText}/>
                                    </Col>
                                    <Col sm={12} md={pageCardData.imgLocation === "top" ? 12 : 6}>
                                        <Card.Body className={pageCardData.imgLocation === "top" && "page-card-text-container"}>
                                            <Card.Title>{card.title}</Card.Title>
                                            {pageCardData.hrVisible && <div className="page-card-title-divider"><hr /></div>}
                                            <Card.Text>{card.text}</Card.Text>
                                            {card.link && <Button variant="primary" href={card.link}>{card.buttonText || "Learn More"}</Button>}
                                        </Card.Body>
                                    </Col>
                                </Row>
                                
                            </Card>
                        </Col>
                    );
                })}
            </Row>
              
        </Container>
    );
};

export default PageCard;
const newPatientConsent = {
    title: "Consent for Treatment",
    text: <><p>I am 18 years of age or older and authorize the Veterinarians and
            staff of {process.env.REACT_APP_DISPLAY_NAME} to examine my pet and administer treatment as is considered
            necessary for my pet's condition.
    </p>
    <p>
            I agree to pay for all services rendered on behalf of my pet at the time services are performed. We
            accept cash, check, Visa™, Mastercard™, Discover™ and CareCredit™ for payment. If price is an issue
            please discuss this with a staff member prior to treatment.
    </p>
    <p>
            I also understand that for extensive procedures an initial payment of one-half the estimate charges may
            be required before my pet is admitted. Estimates for services will be prepared upon request at the time
            of examination of pet. I understand that {process.env.REACT_APP_DISPLAY_NAME} may refuse to provide services for
            any reason.
    </p></>


};

const termsAndAgreements = {
    newPatientConsent
};

export default termsAndAgreements;
import { Container, Row, Col } from "react-bootstrap";

import { Link } from "react-router-dom";

import EverySubHero from "../global/every_subhero";
import EverySVG from "../global/every_svg";

import "./scss/main_stakes.scss";

const MainStakes = () => {
    return (
        <>
            <EverySubHero 
                title={<>Welcome to {process.env.REACT_APP_DISPLAY_NAME}</>}
                text={<>{process.env.REACT_APP_DISPLAY_NAME} is a mixed-animal veterinary practice serving Emmett, Sweet, Boise, Caldwell, Sand Hollow, Letha, New Plymouth, and the surrounding communities in the Gem County, Idaho area.</>}
                bgColor="primary"
                spacerTop={true}
            />
            <Container fluid className="main-stakes-cards-container pb-5">
                <Container className="max-width-xl">
                    <Row>
                        <Col sm={6} lg={4} align="center">
                            <Link to="/services/equine">
                                <EverySVG 
                                    size="large"
                                    // border={true}
                                    imageName="img-large-animal"
                                    bgColor="transparent"
                                    padding="p-0"
                                    color="white"
                                />
                                <h5>Equine</h5>
                            </Link>
                        </Col>
                        <Col sm={6} lg={4} align="center">
                            <Link to="/services/small-animal">
                                <EverySVG 
                                    size="large"
                                    // border={true}
                                    imageName="img-small-animal"
                                    bgColor="transparent"
                                    padding="p-0"
                                    color="white"
                                />
                                <h5>Small Animal</h5>
                            </Link>
                        </Col>
                        <Col sm={6} lg={4} align="center">
                            <Link to="/services">
                                <EverySVG 
                                    size="large"
                                    // border={true}
                                    imageName="img-emergency"
                                    bgColor="transparent"
                                    padding="p-0"
                                    color="white"
                                />
                                <h5>Emergency Services</h5>
                            </Link>
                        </Col>
                        {/* <Col sm={6} lg={3} align="center">
                            <Link to="/services">
                                <EverySVG 
                                    size="large"
                                    // border={true}
                                    imageName="img-mobile"
                                    bgColor="transparent"
                                    padding="p-0"
                                    color="white"
                                />
                                <h5>Mobile Care</h5>
                            </Link>
                        </Col> */}
                    </Row>
                </Container>                       
            </Container>
            <div className="spacer wave-bottom" style={{marginTop: "-5rem", marginBottom: "3rem"}}></div> 
        </>
    );
};

export default MainStakes;
import React from "react";
import { Container } from "react-bootstrap";

import EveryHero from "../global/every_hero";
import ServicesDetailAlt from "./services_detail_alt";

import imgServicesHero from "../../assets/images/dog_cat.jpg";

const SmallAnimal = () => {


    return (
        <>
            <EveryHero image={imgServicesHero} 
                breadcrumb={{active: "Small Animal", previous: "Services", path: "/services"}}
                bgColor="primary"
                title="Small Animal" 
                text={<>At our veterinary clinic, we provide exceptional and empathetic veterinary care for your beloved furry family members at every stage of their lives. Our commitment to delivering high-quality services ensures that your pets receive the best possible care and attention they deserve.</>}
            />
            <Container className="max-width-xl">
                <ServicesDetailAlt data="smallAnimal" title="Small Animal"/>
            </Container>
        </>
    );
};

export default SmallAnimal;
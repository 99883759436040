import React from "react";
import { Link } from "react-router-dom";

import { Container, Row, Col, Stack } from "react-bootstrap";

import LogoWhite from "../../assets/images/logo-AMCEmmett-white.webp";
import iconFacebook from "../../assets/images/icons/iconFacebook.svg";

import "./footer.scss";

const Footer = () => {

    let copyrightYear = new Date().getFullYear();

    return (
        <Container fluid className="mt-auto footer-container">
            <Container className="pt-4 pt-lg-5">
                <Row>
                    <Col xs="auto" sm={2} lg="auto">
                        <Link to="/" className="footer-logo" style={{ textDecoration: "none", color: "#ffffff" }}>
                            <img className="footer-logo-image" src={LogoWhite} alt={"logo"} />
                        </Link>
                    </Col>
                    <Col xs={12} sm={6} lg={3} className="mt-1 footer-logo-text">
                        <Link to="/" style={{ textDecoration: "none", color: "#ffffff" }}>
                            <div>Animal Medical Center <br></br>of Emmett</div>
                        </Link>
                        <Stack gap={0} className="mt-2">
                            <div>2588 Waterwheel Rd</div>
                            <div >Emmett, Idaho 83617</div>
                            <div><a href="tel:208-365-5396">(208) 365-5396</a></div>
                            <div><a href={`mailto: ${process.env.REACT_APP_DISPLAY_EMAIL}`}>{process.env.REACT_APP_DISPLAY_EMAIL}</a></div>
                            <div className="py-2"><a href="https://www.facebook.com/amcemmett" target="_blank" rel="noreferrer"><img src={iconFacebook} alt="facebook" /></a></div>
                        </Stack>
                    </Col>
                    <Col sm={{ span: 10, offset: 2 }} lg={{ span: 5, offset: 0 }} className="footer-office-hours">
                        <div className="footer-section-title">Office Hours</div>
                        <Row>
                            <Col lg={5} className="footer-section-days">Monday - Friday</Col>
                            <Col>7:00am - 6:00pm</Col>
                        </Row>
                        <Row>
                            <Col lg={5} className="footer-section-days">Saturday</Col>
                            <Col>8:00am - 12:00pm</Col>
                        </Row>
                        <Row >
                            <Col lg={5} className="footer-section-days">Sunday</Col>
                            <Col>Closed</Col>
                        </Row>
                    </Col>
                    <Col sm={{ span: 10, offset: 2 }} lg={{ span: 2, offset: 0 }}>
                        <div className="footer-section-title">Navigate</div>
                        <Stack gap={3}>
                            <div><Link to="/services">Services</Link></div>
                            <div><Link to="/about-us">About Us</Link></div>
                            <div><Link to="/contact-us">Contact Us</Link></div>
                            <div><Link
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://animalmedicalcenteremmett.vetsfirstchoice.com/"
                                to="/contact-us">Online Pharmacy
                            </Link></div>
                        </Stack>
                    </Col>
                </Row>
            </Container>
            <Container className="pb-4">
                <hr className="divider" />
                <div className="footer-copyright-line-container">
                    <span className="footer-copyright-line">© {copyrightYear}&nbsp;&nbsp;{process.env.REACT_APP_DISPLAY_NAME}&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                    <span className="footer-copyright-line">This is an <a href="https://everydvm.com" rel="noreferrer">EveryDVM</a> website.</span>
                </div>
            </Container>
        </Container>
    );
};

export default Footer;
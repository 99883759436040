import React from "react";
import { Container } from "react-bootstrap";

import EveryHero from "../global/every_hero";
import ServicesDetailAlt from "./services_detail_alt";

import imgServicesHero from "../../assets/images/horse_colt.jpg";

const Equine = () => {

    return (
        <>
            <EveryHero image={imgServicesHero} 
                breadcrumb={{active: "Equine", previous: "Services", path: "/services"}}
                bgColor="primary"
                title="Equine" 
                mobileAlign="mobile-align-r"
                text={<>Horses are large animals with unique needs, and just like all animals, they need veterinary care from time to time. At {process.env.REACT_APP_DISPLAY_NAME}, we offer mobile equine veterinary services to make caring for your horses simple and convenient. Whether you are in need of wellness services, injury treatment, dental services, or even pregnancy services, we will come to you and deliver the high-quality, compassionate care your horse needs.</>}
            />
            <Container className="max-width-xl">
                <ServicesDetailAlt data="equine" title="Equine"/>
            </Container>
        </>
    );
};

export default Equine;
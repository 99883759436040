import React from "react";
import { Container } from "react-bootstrap";

import EveryHero from "../global/every_hero";
import EveryCircle from "../global/every_circle";

import imgServicesHero from "../../assets/images/saved_from_old_site/slideAbout.webp";

const AboutUs = () => {


    return (
        <>
            <EveryHero 
                image={imgServicesHero} 
                title="About Us" 
                text={<>Dr. Dan Dombroski took over the practice in 2015 and cares for animals both in-office and on farm calls. The staff at {process.env.REACT_APP_DISPLAY_NAME} is devoted to providing high-quality, affordable services for pets and farm animals in Gem County, and when you choose us, you can rest assured knowing that your animals are in good hands.</>}
                bgColor="primary"
            />
            <Container className="max-width-xl">
                <EveryCircle data="team"/>
            </Container>
        </>
    );
};

export default AboutUs;
import React from "react";
import { Container } from "react-bootstrap";

import EveryHero from "../global/every_hero";
import EveryFAQ from "../global/every_faq";

import imgScratchpayHero from "../../assets/images/imageHeroDogVet.webp";
import imgScratchLogo from "../../assets/images/Scratch_Financial-Logo-Primary-1.webp";

const Scratchpay = () => {

    return (
        <>
            <EveryHero image={imgScratchpayHero} 
                bgColor="primary"
                title={<img src={imgScratchLogo} alt="" style={{height: "4rem"}}/>}
                text={
                    <>
                        <p>Scratchpay offers simple and affordable payment plans for large veterinary bills. Finding your payment plan does not affect your credit, takes just a couple of minutes and can be done from your smartphone, tablet or computer.</p>
                    </>
                }
            />
            <Container className="max-width-xl">                
                <EveryFAQ data="scratchpayData" columns={2} />
            </Container>
        </>
    );
};

export default Scratchpay;